import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiActivity />,
        title: 'IT Management',
        description: 'Optimize your tech operations with our IT Management Services. From cloud management to cybersecurity, we tailor solutions to your business needs, ensuring seamless, secure operations. Let our experts handle your IT challenges, so you can focus on growth.'
    },
    {
        icon: <FiCast />,
        title: 'Security Management',
        description: 'Elevate your security posture with our Cybersecurity Management services. We offer customized security solutions that address your unique challenges, from threat detection to prevention and response. Our team of experts works tirelessly to safeguard your operations, ensuring your data and digital assets remain protected. Partner with us to fortify your defenses and focus on your business with peace of mind.'
    },
    {
        icon: <FiMap />,
        title: 'Software Development',
        description: 'Elevate your business with our Software Development Services. We craft custom software solutions, from mobile applications to enterprise systems, tailored to meet your specific needs. Our team of developers uses the latest technologies to ensure your projects are innovative, efficient, and scalable. Let us transform your ideas into reality, empowering your business to thrive in the digital landscape.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;