import React from 'react';
import {FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Typed from 'react-typed';



const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.png" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">SanSol Consulting<br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Consulting.",
                                            "IT.",
                                            "Cybersecurity.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>SanSol is a Managed Service Provider in Northern New Jersey. We specialize in full-service IT and Cybersecurity but help a wide range of clients with a wide range of budgets.</p>
                                <p>We were founded in May of 2023 and wholeheartedly believe that syupport should come from a place of science and empathy, not profit and loss. </p>
                                <div className="read-more-btn mt--40">
                                <Link className="btn-default btn-medium round btn-icon" to="/contact">Contact Us <i className="icon"><FiArrowRight /></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
